require('alpinejs');
require('leaflet');

window.listing = function() {
    return {
        scrollToResults(el) {
            if (RegExp(/\/.*\/p\d/).test(window.location.pathname) && window.pageYOffset == 0) {
                window.scrollTo(0,el.getBoundingClientRect().top - (document.getElementById('main-site-nav').offsetHeight + 20));
            }  
        }
    }
}

window.searchBar = function() {
    return {
        show: false,
        open() { this.show = true },
        close() { this.show = false },
        isOpen() { return this.show === true },
        formSubmit($event) {
            
            var searchInput = $event.target[0];
            if (searchInput.name != 'q' || searchInput.value == '') {
                $event.preventDefault();
            }
        },
    }
};

window.openStreetMap = function($el) {
    var $mapDiv = $el.getElementsByClassName('map')[0];
    return {
        letsLoadIt() {
            // console.log($mapDiv.id);
            var mymap = L.map($mapDiv.id).setView([$mapDiv.dataset.lat,$mapDiv.dataset.lng], $mapDiv.dataset.zoom)
            var layer = new  L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
            mymap.addLayer(layer);
            var marker = L.marker([$mapDiv.dataset.lat,$mapDiv.dataset.lng]).addTo(mymap);
            marker.bindPopup($mapDiv.dataset.address.replaceAll(',','<br>'));
        }
    };

} 